import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
};

export type ConfirmEmailInput = {
  token: Scalars['String']['input'];
};

export type CreatePermissionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  scopeId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type CreateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  permissionIds: Array<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  type: GuardType;
};

export type CreateScopeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: GuardType;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  roleIds: Array<Scalars['Int']['input']>;
};

export type DeletePermissionInput = {
  permissionId: Scalars['Int']['input'];
};

export type DeleteRoleInput = {
  roleId: Scalars['Int']['input'];
};

export type DeleteScopeInput = {
  scopeId: Scalars['Int']['input'];
};

/** Different stages of the login process */
export enum ELoginType {
  ConfirmEmail = 'ConfirmEmail',
  GenerateOtp = 'GenerateOtp',
  SetPassword = 'SetPassword',
  VerifyOtp = 'VerifyOtp',
}

export type EnableOtpInput = {
  token: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type GenerateOtpInput = {
  userId: Scalars['Int']['input'];
};

export type GenerateOtpResponse = {
  __typename?: 'GenerateOtpResponse';
  otpAuthUrl: Scalars['String']['output'];
  otpSecret: Scalars['String']['output'];
};

export type GetPermissionInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetPermissionsInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  roleIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetRoleInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetRolesInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetScopeInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetScopesInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type GetUsersInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  roleIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum GuardType {
  Admin = 'ADMIN',
  System = 'SYSTEM',
  User = 'USER',
}

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  type: ELoginType;
  user: UserInfo;
};

export type LogoutInput = {
  userId: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  confirmEmail: Scalars['Boolean']['output'];
  createPermission: Permission;
  createRole: Role;
  createScope: Scope;
  createUser: User;
  deletePermission: Scalars['Boolean']['output'];
  deleteRole: Scalars['Boolean']['output'];
  deleteScope: Scalars['Boolean']['output'];
  enableOtp: Scalars['Boolean']['output'];
  generateOtp: GenerateOtpResponse;
  login: LoginResponse;
  logout: Scalars['Boolean']['output'];
  refreshTokens: RefreshTokensResponse;
  register: UserInfo;
  setPassword: Scalars['Boolean']['output'];
  testToken: Scalars['String']['output'];
  updatePermission: Permission;
  updateRole: Role;
  updateScope: Scope;
  updateUser: User;
  verifyOtp: VerifyOtpResponse;
};

export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};

export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};

export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type MutationCreateScopeArgs = {
  input: CreateScopeInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeletePermissionArgs = {
  input: DeletePermissionInput;
};

export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};

export type MutationDeleteScopeArgs = {
  input: DeleteScopeInput;
};

export type MutationEnableOtpArgs = {
  input: EnableOtpInput;
};

export type MutationGenerateOtpArgs = {
  input: GenerateOtpInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationLogoutArgs = {
  input: LogoutInput;
};

export type MutationRefreshTokensArgs = {
  input: RefreshTokensInput;
};

export type MutationRegisterArgs = {
  input: RegisterInput;
};

export type MutationSetPasswordArgs = {
  input: SetPasswordInput;
};

export type MutationTestTokenArgs = {
  input: TestTokenInput;
};

export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationUpdateScopeArgs = {
  input: UpdateScopeInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationVerifyOtpArgs = {
  input: VerifyOtpInput;
};

export type PaginationInput = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
};

export type Permission = {
  __typename?: 'Permission';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  roles?: Maybe<Roles>;
  scope?: Maybe<Scope>;
  scopeId?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type PermissionRolesArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type Permissions = {
  __typename?: 'Permissions';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<Permission>>;
};

export type Query = {
  __typename?: 'Query';
  getPermission: Permission;
  getPermissions: Permissions;
  getRole: Role;
  getRoles: Roles;
  getScope: Scope;
  getScopes: Scopes;
  getUser: User;
  getUsers: Users;
};

export type QueryGetPermissionArgs = {
  input: GetPermissionInput;
};

export type QueryGetPermissionsArgs = {
  input: GetPermissionsInput;
};

export type QueryGetRoleArgs = {
  input: GetRoleInput;
};

export type QueryGetRolesArgs = {
  input: GetRolesInput;
};

export type QueryGetScopeArgs = {
  input: GetScopeInput;
};

export type QueryGetScopesArgs = {
  input: GetScopesInput;
};

export type QueryGetUserArgs = {
  input: GetUserInput;
};

export type QueryGetUsersArgs = {
  input: GetUsersInput;
};

export type RefreshTokensInput = {
  refreshToken: Scalars['String']['input'];
};

export type RefreshTokensResponse = {
  __typename?: 'RefreshTokensResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type RegisterInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  roleIds: Array<Scalars['Int']['input']>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  permissions?: Maybe<Permissions>;
  title: Scalars['String']['output'];
  type?: Maybe<GuardType>;
  users?: Maybe<Array<UserRoles>>;
};

export type RolePermissionsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type Roles = {
  __typename?: 'Roles';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<Role>>;
};

export type Scope = {
  __typename?: 'Scope';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  permissions?: Maybe<Permissions>;
  title: Scalars['String']['output'];
  type?: Maybe<GuardType>;
};

export type ScopePermissionsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type Scopes = {
  __typename?: 'Scopes';
  count: Scalars['Int']['output'];
  cursor: Scalars['Int']['output'];
  nodes: Array<Scope>;
};

export type SetPasswordInput = {
  password: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type TestTokenInput = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePermissionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  roleIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  scopeId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: GuardType;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateScopeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: GuardType;
};

export type UpdateUserInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isEmailConfirmed: Scalars['Boolean']['output'];
  isOtpVerified: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  otpSecret?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<UserRoles>>;
  tempPassword?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  roles?: Maybe<Array<UserRoles>>;
};

export type UserRoles = {
  __typename?: 'UserRoles';
  role?: Maybe<Role>;
  roleId: Scalars['Int']['output'];
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
};

export type Users = {
  __typename?: 'Users';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<User>>;
};

export type VerifyOtpInput = {
  token: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type VerifyOtpResponse = {
  __typename?: 'VerifyOtpResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type ConfirmEmailMutationVariables = Exact<{
  input: ConfirmEmailInput;
}>;

export type ConfirmEmailMutation = {
  __typename?: 'Mutation';
  confirmEmail: boolean;
};

export type EnableOtpMutationVariables = Exact<{
  input: EnableOtpInput;
}>;

export type EnableOtpMutation = { __typename?: 'Mutation'; enableOtp: boolean };

export type GenerateOtpMutationVariables = Exact<{
  input: GenerateOtpInput;
}>;

export type GenerateOtpMutation = {
  __typename?: 'Mutation';
  generateOtp: {
    __typename?: 'GenerateOtpResponse';
    otpSecret: string;
    otpAuthUrl: string;
  };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginResponse';
    type: ELoginType;
    user: {
      __typename?: 'UserInfo';
      id: number;
      email: string;
      firstName: string;
      lastName: string;
      roles?: Array<{
        __typename?: 'UserRoles';
        role?: {
          __typename?: 'Role';
          id: number;
          title: string;
          description?: string | null;
          permissions?: {
            __typename?: 'Permissions';
            cursor?: number | null;
            count?: number | null;
            nodes?: Array<{
              __typename?: 'Permission';
              id: number;
              title: string;
              description?: string | null;
            }> | null;
          } | null;
        } | null;
      }> | null;
    };
  };
};

export type LogoutMutationVariables = Exact<{
  input: LogoutInput;
}>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type RefreshTokensMutationVariables = Exact<{
  input: RefreshTokensInput;
}>;

export type RefreshTokensMutation = {
  __typename?: 'Mutation';
  refreshTokens: {
    __typename?: 'RefreshTokensResponse';
    accessToken: string;
    refreshToken: string;
  };
};

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  register: {
    __typename?: 'UserInfo';
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          cursor?: number | null;
          count?: number | null;
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type SetPasswordMutationVariables = Exact<{
  input: SetPasswordInput;
}>;

export type SetPasswordMutation = {
  __typename?: 'Mutation';
  setPassword: boolean;
};

export type VerifyOtpMutationVariables = Exact<{
  input: VerifyOtpInput;
}>;

export type VerifyOtpMutation = {
  __typename?: 'Mutation';
  verifyOtp: {
    __typename?: 'VerifyOtpResponse';
    accessToken: string;
    refreshToken: string;
  };
};

export type CreatePermissionMutationVariables = Exact<{
  input: CreatePermissionInput;
}>;

export type CreatePermissionMutation = {
  __typename?: 'Mutation';
  createPermission: {
    __typename?: 'Permission';
    id: number;
    title: string;
    description?: string | null;
    scope?: {
      __typename?: 'Scope';
      id: number;
      title: string;
      description?: string | null;
    } | null;
    roles?: {
      __typename?: 'Roles';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Role';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type DeletePermissionMutationVariables = Exact<{
  input: DeletePermissionInput;
}>;

export type DeletePermissionMutation = {
  __typename?: 'Mutation';
  deletePermission: boolean;
};

export type GetPermissionQueryVariables = Exact<{
  input: GetPermissionInput;
}>;

export type GetPermissionQuery = {
  __typename?: 'Query';
  getPermission: {
    __typename?: 'Permission';
    id: number;
    title: string;
    description?: string | null;
    scope?: {
      __typename?: 'Scope';
      id: number;
      title: string;
      description?: string | null;
    } | null;
    roles?: {
      __typename?: 'Roles';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Role';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type GetPermissionsQueryVariables = Exact<{
  input: GetPermissionsInput;
}>;

export type GetPermissionsQuery = {
  __typename?: 'Query';
  getPermissions: {
    __typename?: 'Permissions';
    cursor?: number | null;
    count?: number | null;
    nodes?: Array<{
      __typename?: 'Permission';
      id: number;
      title: string;
      description?: string | null;
      scope?: {
        __typename?: 'Scope';
        id: number;
        title: string;
        description?: string | null;
      } | null;
      roles?: {
        __typename?: 'Roles';
        cursor?: number | null;
        count?: number | null;
        nodes?: Array<{
          __typename?: 'Role';
          description?: string | null;
          id: number;
          title: string;
        }> | null;
      } | null;
    }> | null;
  };
};

export type PermissionFragment = {
  __typename?: 'Permission';
  id: number;
  title: string;
  description?: string | null;
  scope?: {
    __typename?: 'Scope';
    id: number;
    title: string;
    description?: string | null;
  } | null;
  roles?: {
    __typename?: 'Roles';
    cursor?: number | null;
    count?: number | null;
    nodes?: Array<{
      __typename?: 'Role';
      description?: string | null;
      id: number;
      title: string;
    }> | null;
  } | null;
};

export type UpdatePermissionMutationVariables = Exact<{
  input: UpdatePermissionInput;
}>;

export type UpdatePermissionMutation = {
  __typename?: 'Mutation';
  updatePermission: {
    __typename?: 'Permission';
    id: number;
    title: string;
    description?: string | null;
    scope?: {
      __typename?: 'Scope';
      id: number;
      title: string;
      description?: string | null;
    } | null;
    roles?: {
      __typename?: 'Roles';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Role';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;

export type CreateRoleMutation = {
  __typename?: 'Mutation';
  createRole: {
    __typename?: 'Role';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      count?: number | null;
      cursor?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type DeleteRoleMutationVariables = Exact<{
  input: DeleteRoleInput;
}>;

export type DeleteRoleMutation = {
  __typename?: 'Mutation';
  deleteRole: boolean;
};

export type GetRoleQueryVariables = Exact<{
  input: GetRoleInput;
}>;

export type GetRoleQuery = {
  __typename?: 'Query';
  getRole: {
    __typename?: 'Role';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      count?: number | null;
      cursor?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type GetRolesQueryVariables = Exact<{
  input: GetRolesInput;
}>;

export type GetRolesQuery = {
  __typename?: 'Query';
  getRoles: {
    __typename?: 'Roles';
    count?: number | null;
    cursor?: number | null;
    nodes?: Array<{
      __typename?: 'Role';
      id: number;
      title: string;
      description?: string | null;
      type?: GuardType | null;
      permissions?: {
        __typename?: 'Permissions';
        count?: number | null;
        cursor?: number | null;
        nodes?: Array<{
          __typename?: 'Permission';
          description?: string | null;
          id: number;
          title: string;
        }> | null;
      } | null;
    }> | null;
  };
};

export type RoleFragment = {
  __typename?: 'Role';
  id: number;
  title: string;
  description?: string | null;
  type?: GuardType | null;
  permissions?: {
    __typename?: 'Permissions';
    count?: number | null;
    cursor?: number | null;
    nodes?: Array<{
      __typename?: 'Permission';
      description?: string | null;
      id: number;
      title: string;
    }> | null;
  } | null;
};

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;

export type UpdateRoleMutation = {
  __typename?: 'Mutation';
  updateRole: {
    __typename?: 'Role';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      count?: number | null;
      cursor?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type CreateScopeMutationVariables = Exact<{
  input: CreateScopeInput;
}>;

export type CreateScopeMutation = {
  __typename?: 'Mutation';
  createScope: {
    __typename?: 'Scope';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type DeleteScopeMutationVariables = Exact<{
  input: DeleteScopeInput;
}>;

export type DeleteScopeMutation = {
  __typename?: 'Mutation';
  deleteScope: boolean;
};

export type GetScopeQueryVariables = Exact<{
  input: GetScopeInput;
}>;

export type GetScopeQuery = {
  __typename?: 'Query';
  getScope: {
    __typename?: 'Scope';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type GetScopesQueryVariables = Exact<{
  input: GetScopesInput;
}>;

export type GetScopesQuery = {
  __typename?: 'Query';
  getScopes: {
    __typename?: 'Scopes';
    count: number;
    cursor: number;
    nodes: Array<{
      __typename?: 'Scope';
      id: number;
      title: string;
      description?: string | null;
      type?: GuardType | null;
      permissions?: {
        __typename?: 'Permissions';
        cursor?: number | null;
        count?: number | null;
        nodes?: Array<{
          __typename?: 'Permission';
          description?: string | null;
          id: number;
          title: string;
        }> | null;
      } | null;
    }>;
  };
};

export type ScopeFragment = {
  __typename?: 'Scope';
  id: number;
  title: string;
  description?: string | null;
  type?: GuardType | null;
  permissions?: {
    __typename?: 'Permissions';
    cursor?: number | null;
    count?: number | null;
    nodes?: Array<{
      __typename?: 'Permission';
      description?: string | null;
      id: number;
      title: string;
    }> | null;
  } | null;
};

export type UpdateScopeMutationVariables = Exact<{
  input: UpdateScopeInput;
}>;

export type UpdateScopeMutation = {
  __typename?: 'Mutation';
  updateScope: {
    __typename?: 'Scope';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id: number;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    password?: string | null;
    tempPassword?: string | null;
    isEmailConfirmed: boolean;
    otpSecret?: string | null;
    refreshToken?: string | null;
    createdAt: string;
    updatedAt: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type GetUserQueryVariables = Exact<{
  input: GetUserInput;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'User';
    id: number;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    password?: string | null;
    tempPassword?: string | null;
    isEmailConfirmed: boolean;
    otpSecret?: string | null;
    refreshToken?: string | null;
    createdAt: string;
    updatedAt: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type GetUsersQueryVariables = Exact<{
  input: GetUsersInput;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'Users';
    count?: number | null;
    cursor?: number | null;
    nodes?: Array<{
      __typename?: 'User';
      id: number;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      password?: string | null;
      tempPassword?: string | null;
      isEmailConfirmed: boolean;
      otpSecret?: string | null;
      refreshToken?: string | null;
      createdAt: string;
      updatedAt: string;
      roles?: Array<{
        __typename?: 'UserRoles';
        role?: {
          __typename?: 'Role';
          id: number;
          title: string;
          description?: string | null;
          permissions?: {
            __typename?: 'Permissions';
            nodes?: Array<{
              __typename?: 'Permission';
              id: number;
              title: string;
              description?: string | null;
            }> | null;
          } | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type UserFragment = {
  __typename?: 'User';
  id: number;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  tempPassword?: string | null;
  isEmailConfirmed: boolean;
  otpSecret?: string | null;
  refreshToken?: string | null;
  createdAt: string;
  updatedAt: string;
  roles?: Array<{
    __typename?: 'UserRoles';
    role?: {
      __typename?: 'Role';
      id: number;
      title: string;
      description?: string | null;
      permissions?: {
        __typename?: 'Permissions';
        nodes?: Array<{
          __typename?: 'Permission';
          id: number;
          title: string;
          description?: string | null;
        }> | null;
      } | null;
    } | null;
  }> | null;
};

export type UserInfoFragment = {
  __typename?: 'UserInfo';
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  roles?: Array<{
    __typename?: 'UserRoles';
    role?: {
      __typename?: 'Role';
      id: number;
      title: string;
      description?: string | null;
      permissions?: {
        __typename?: 'Permissions';
        cursor?: number | null;
        count?: number | null;
        nodes?: Array<{
          __typename?: 'Permission';
          id: number;
          title: string;
          description?: string | null;
        }> | null;
      } | null;
    } | null;
  }> | null;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: number;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    password?: string | null;
    tempPassword?: string | null;
    isEmailConfirmed: boolean;
    otpSecret?: string | null;
    refreshToken?: string | null;
    createdAt: string;
    updatedAt: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
  };
};

export const PermissionFragmentDoc = gql`
  fragment permission on Permission {
    id
    title
    description
    scope {
      id
      title
      description
    }
    roles {
      cursor
      count
      nodes {
        description
        id
        title
      }
    }
  }
`;
export const RoleFragmentDoc = gql`
  fragment role on Role {
    id
    title
    description
    type
    permissions {
      count
      cursor
      nodes {
        description
        id
        title
      }
    }
  }
`;
export const ScopeFragmentDoc = gql`
  fragment scope on Scope {
    id
    title
    description
    type
    permissions {
      cursor
      count
      nodes {
        description
        id
        title
      }
    }
  }
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    id
    email
    firstName
    lastName
    password
    tempPassword
    isEmailConfirmed
    otpSecret
    refreshToken
    createdAt
    updatedAt
    roles {
      role {
        id
        title
        description
        permissions {
          nodes {
            id
            title
            description
          }
        }
      }
    }
  }
`;
export const UserInfoFragmentDoc = gql`
  fragment userInfo on UserInfo {
    id
    email
    firstName
    lastName
    roles {
      role {
        id
        title
        description
        permissions {
          cursor
          count
          nodes {
            id
            title
            description
          }
        }
      }
    }
  }
`;
export const ConfirmEmailDocument = gql`
  mutation confirmEmail($input: ConfirmEmailInput!) {
    confirmEmail(input: $input)
  }
`;
export const EnableOtpDocument = gql`
  mutation enableOtp($input: EnableOtpInput!) {
    enableOtp(input: $input)
  }
`;
export const GenerateOtpDocument = gql`
  mutation generateOtp($input: GenerateOtpInput!) {
    generateOtp(input: $input) {
      otpSecret
      otpAuthUrl
    }
  }
`;
export const LoginDocument = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      type
      user {
        ...userInfo
      }
    }
  }
  ${UserInfoFragmentDoc}
`;
export const LogoutDocument = gql`
  mutation logout($input: LogoutInput!) {
    logout(input: $input)
  }
`;
export const RefreshTokensDocument = gql`
  mutation refreshTokens($input: RefreshTokensInput!) {
    refreshTokens(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
export const RegisterDocument = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      ...userInfo
    }
  }
  ${UserInfoFragmentDoc}
`;
export const SetPasswordDocument = gql`
  mutation setPassword($input: SetPasswordInput!) {
    setPassword(input: $input)
  }
`;
export const VerifyOtpDocument = gql`
  mutation verifyOtp($input: VerifyOtpInput!) {
    verifyOtp(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
export const CreatePermissionDocument = gql`
  mutation createPermission($input: CreatePermissionInput!) {
    createPermission(input: $input) {
      ...permission
    }
  }
  ${PermissionFragmentDoc}
`;
export const DeletePermissionDocument = gql`
  mutation deletePermission($input: DeletePermissionInput!) {
    deletePermission(input: $input)
  }
`;
export const GetPermissionDocument = gql`
  query getPermission($input: GetPermissionInput!) {
    getPermission(input: $input) {
      ...permission
    }
  }
  ${PermissionFragmentDoc}
`;
export const GetPermissionsDocument = gql`
  query getPermissions($input: GetPermissionsInput!) {
    getPermissions(input: $input) {
      cursor
      count
      nodes {
        ...permission
      }
    }
  }
  ${PermissionFragmentDoc}
`;
export const UpdatePermissionDocument = gql`
  mutation updatePermission($input: UpdatePermissionInput!) {
    updatePermission(input: $input) {
      ...permission
    }
  }
  ${PermissionFragmentDoc}
`;
export const CreateRoleDocument = gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      ...role
    }
  }
  ${RoleFragmentDoc}
`;
export const DeleteRoleDocument = gql`
  mutation deleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input)
  }
`;
export const GetRoleDocument = gql`
  query getRole($input: GetRoleInput!) {
    getRole(input: $input) {
      ...role
    }
  }
  ${RoleFragmentDoc}
`;
export const GetRolesDocument = gql`
  query getRoles($input: GetRolesInput!) {
    getRoles(input: $input) {
      count
      cursor
      nodes {
        ...role
      }
    }
  }
  ${RoleFragmentDoc}
`;
export const UpdateRoleDocument = gql`
  mutation updateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      ...role
    }
  }
  ${RoleFragmentDoc}
`;
export const CreateScopeDocument = gql`
  mutation createScope($input: CreateScopeInput!) {
    createScope(input: $input) {
      ...scope
    }
  }
  ${ScopeFragmentDoc}
`;
export const DeleteScopeDocument = gql`
  mutation deleteScope($input: DeleteScopeInput!) {
    deleteScope(input: $input)
  }
`;
export const GetScopeDocument = gql`
  query getScope($input: GetScopeInput!) {
    getScope(input: $input) {
      ...scope
    }
  }
  ${ScopeFragmentDoc}
`;
export const GetScopesDocument = gql`
  query getScopes($input: GetScopesInput!) {
    getScopes(input: $input) {
      count
      cursor
      nodes {
        ...scope
      }
    }
  }
  ${ScopeFragmentDoc}
`;
export const UpdateScopeDocument = gql`
  mutation updateScope($input: UpdateScopeInput!) {
    updateScope(input: $input) {
      ...scope
    }
  }
  ${ScopeFragmentDoc}
`;
export const CreateUserDocument = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const GetUserDocument = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const GetUsersDocument = gql`
  query getUsers($input: GetUsersInput!) {
    getUsers(input: $input) {
      count
      cursor
      nodes {
        ...user
      }
    }
  }
  ${UserFragmentDoc}
`;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export type Requester<C = {}> = <R, V>(
  doc: DocumentNode,
  vars?: V,
  options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
  return {
    confirmEmail(
      variables: ConfirmEmailMutationVariables,
      options?: C,
    ): Promise<ConfirmEmailMutation> {
      return requester<ConfirmEmailMutation, ConfirmEmailMutationVariables>(
        ConfirmEmailDocument,
        variables,
        options,
      ) as Promise<ConfirmEmailMutation>;
    },
    enableOtp(
      variables: EnableOtpMutationVariables,
      options?: C,
    ): Promise<EnableOtpMutation> {
      return requester<EnableOtpMutation, EnableOtpMutationVariables>(
        EnableOtpDocument,
        variables,
        options,
      ) as Promise<EnableOtpMutation>;
    },
    generateOtp(
      variables: GenerateOtpMutationVariables,
      options?: C,
    ): Promise<GenerateOtpMutation> {
      return requester<GenerateOtpMutation, GenerateOtpMutationVariables>(
        GenerateOtpDocument,
        variables,
        options,
      ) as Promise<GenerateOtpMutation>;
    },
    login(
      variables: LoginMutationVariables,
      options?: C,
    ): Promise<LoginMutation> {
      return requester<LoginMutation, LoginMutationVariables>(
        LoginDocument,
        variables,
        options,
      ) as Promise<LoginMutation>;
    },
    logout(
      variables: LogoutMutationVariables,
      options?: C,
    ): Promise<LogoutMutation> {
      return requester<LogoutMutation, LogoutMutationVariables>(
        LogoutDocument,
        variables,
        options,
      ) as Promise<LogoutMutation>;
    },
    refreshTokens(
      variables: RefreshTokensMutationVariables,
      options?: C,
    ): Promise<RefreshTokensMutation> {
      return requester<RefreshTokensMutation, RefreshTokensMutationVariables>(
        RefreshTokensDocument,
        variables,
        options,
      ) as Promise<RefreshTokensMutation>;
    },
    register(
      variables: RegisterMutationVariables,
      options?: C,
    ): Promise<RegisterMutation> {
      return requester<RegisterMutation, RegisterMutationVariables>(
        RegisterDocument,
        variables,
        options,
      ) as Promise<RegisterMutation>;
    },
    setPassword(
      variables: SetPasswordMutationVariables,
      options?: C,
    ): Promise<SetPasswordMutation> {
      return requester<SetPasswordMutation, SetPasswordMutationVariables>(
        SetPasswordDocument,
        variables,
        options,
      ) as Promise<SetPasswordMutation>;
    },
    verifyOtp(
      variables: VerifyOtpMutationVariables,
      options?: C,
    ): Promise<VerifyOtpMutation> {
      return requester<VerifyOtpMutation, VerifyOtpMutationVariables>(
        VerifyOtpDocument,
        variables,
        options,
      ) as Promise<VerifyOtpMutation>;
    },
    createPermission(
      variables: CreatePermissionMutationVariables,
      options?: C,
    ): Promise<CreatePermissionMutation> {
      return requester<
        CreatePermissionMutation,
        CreatePermissionMutationVariables
      >(
        CreatePermissionDocument,
        variables,
        options,
      ) as Promise<CreatePermissionMutation>;
    },
    deletePermission(
      variables: DeletePermissionMutationVariables,
      options?: C,
    ): Promise<DeletePermissionMutation> {
      return requester<
        DeletePermissionMutation,
        DeletePermissionMutationVariables
      >(
        DeletePermissionDocument,
        variables,
        options,
      ) as Promise<DeletePermissionMutation>;
    },
    getPermission(
      variables: GetPermissionQueryVariables,
      options?: C,
    ): Promise<GetPermissionQuery> {
      return requester<GetPermissionQuery, GetPermissionQueryVariables>(
        GetPermissionDocument,
        variables,
        options,
      ) as Promise<GetPermissionQuery>;
    },
    getPermissions(
      variables: GetPermissionsQueryVariables,
      options?: C,
    ): Promise<GetPermissionsQuery> {
      return requester<GetPermissionsQuery, GetPermissionsQueryVariables>(
        GetPermissionsDocument,
        variables,
        options,
      ) as Promise<GetPermissionsQuery>;
    },
    updatePermission(
      variables: UpdatePermissionMutationVariables,
      options?: C,
    ): Promise<UpdatePermissionMutation> {
      return requester<
        UpdatePermissionMutation,
        UpdatePermissionMutationVariables
      >(
        UpdatePermissionDocument,
        variables,
        options,
      ) as Promise<UpdatePermissionMutation>;
    },
    createRole(
      variables: CreateRoleMutationVariables,
      options?: C,
    ): Promise<CreateRoleMutation> {
      return requester<CreateRoleMutation, CreateRoleMutationVariables>(
        CreateRoleDocument,
        variables,
        options,
      ) as Promise<CreateRoleMutation>;
    },
    deleteRole(
      variables: DeleteRoleMutationVariables,
      options?: C,
    ): Promise<DeleteRoleMutation> {
      return requester<DeleteRoleMutation, DeleteRoleMutationVariables>(
        DeleteRoleDocument,
        variables,
        options,
      ) as Promise<DeleteRoleMutation>;
    },
    getRole(
      variables: GetRoleQueryVariables,
      options?: C,
    ): Promise<GetRoleQuery> {
      return requester<GetRoleQuery, GetRoleQueryVariables>(
        GetRoleDocument,
        variables,
        options,
      ) as Promise<GetRoleQuery>;
    },
    getRoles(
      variables: GetRolesQueryVariables,
      options?: C,
    ): Promise<GetRolesQuery> {
      return requester<GetRolesQuery, GetRolesQueryVariables>(
        GetRolesDocument,
        variables,
        options,
      ) as Promise<GetRolesQuery>;
    },
    updateRole(
      variables: UpdateRoleMutationVariables,
      options?: C,
    ): Promise<UpdateRoleMutation> {
      return requester<UpdateRoleMutation, UpdateRoleMutationVariables>(
        UpdateRoleDocument,
        variables,
        options,
      ) as Promise<UpdateRoleMutation>;
    },
    createScope(
      variables: CreateScopeMutationVariables,
      options?: C,
    ): Promise<CreateScopeMutation> {
      return requester<CreateScopeMutation, CreateScopeMutationVariables>(
        CreateScopeDocument,
        variables,
        options,
      ) as Promise<CreateScopeMutation>;
    },
    deleteScope(
      variables: DeleteScopeMutationVariables,
      options?: C,
    ): Promise<DeleteScopeMutation> {
      return requester<DeleteScopeMutation, DeleteScopeMutationVariables>(
        DeleteScopeDocument,
        variables,
        options,
      ) as Promise<DeleteScopeMutation>;
    },
    getScope(
      variables: GetScopeQueryVariables,
      options?: C,
    ): Promise<GetScopeQuery> {
      return requester<GetScopeQuery, GetScopeQueryVariables>(
        GetScopeDocument,
        variables,
        options,
      ) as Promise<GetScopeQuery>;
    },
    getScopes(
      variables: GetScopesQueryVariables,
      options?: C,
    ): Promise<GetScopesQuery> {
      return requester<GetScopesQuery, GetScopesQueryVariables>(
        GetScopesDocument,
        variables,
        options,
      ) as Promise<GetScopesQuery>;
    },
    updateScope(
      variables: UpdateScopeMutationVariables,
      options?: C,
    ): Promise<UpdateScopeMutation> {
      return requester<UpdateScopeMutation, UpdateScopeMutationVariables>(
        UpdateScopeDocument,
        variables,
        options,
      ) as Promise<UpdateScopeMutation>;
    },
    createUser(
      variables: CreateUserMutationVariables,
      options?: C,
    ): Promise<CreateUserMutation> {
      return requester<CreateUserMutation, CreateUserMutationVariables>(
        CreateUserDocument,
        variables,
        options,
      ) as Promise<CreateUserMutation>;
    },
    getUser(
      variables: GetUserQueryVariables,
      options?: C,
    ): Promise<GetUserQuery> {
      return requester<GetUserQuery, GetUserQueryVariables>(
        GetUserDocument,
        variables,
        options,
      ) as Promise<GetUserQuery>;
    },
    getUsers(
      variables: GetUsersQueryVariables,
      options?: C,
    ): Promise<GetUsersQuery> {
      return requester<GetUsersQuery, GetUsersQueryVariables>(
        GetUsersDocument,
        variables,
        options,
      ) as Promise<GetUsersQuery>;
    },
    updateUser(
      variables: UpdateUserMutationVariables,
      options?: C,
    ): Promise<UpdateUserMutation> {
      return requester<UpdateUserMutation, UpdateUserMutationVariables>(
        UpdateUserDocument,
        variables,
        options,
      ) as Promise<UpdateUserMutation>;
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
