export enum endPoints {
  DEFAULT_PAGE = '/',
  AUTHORIZATION_PAGE = '*',

  RESET_PASSWORD = '/reset-password',

  CONFIRM_EMAIL = 'confirm-email',

  ROLE = '/access/role',
  ROLE_CREATE = '/access/role/create',
  ROLE_UPDATE = '/access/role/update/:roleId',

  SCOPE = '/access/scope',
  SCOPE_CREATE = '/access/scope/create',
  SCOPE_UPDATE = '/access/scope/update/:scopeId',

  PERMISSION = '/access/permission',
  PERMISSION_CREATE = '/access/permission/create',
  PERMISSION_UPDATE = '/access/permission/update/:permissionId',

  USER = '/user',
  USER_CREATE = '/user/create',
  USER_UPDATE = '/user/update/:userId',

  SETTINGS = '/settings',
  CHANGE_PASSWORD = '/settings/change-password',
}
